import React from 'react';
import {
    Select,
    MenuItem,
    CircularProgress,
    IconButton,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip
} from '@material-ui/core';
import Moment from 'moment';
import PubSub from 'pubsub-js'

import RefreshIcon from '@material-ui/icons/Refresh';
import { getReportJob } from '@apricityhealth/web-common-lib/utils/Services';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { Patient } from '@apricityhealth/web-common-lib/components/Patient';
import SelectPatient from '@apricityhealth/web-common-lib/components/SelectPatient';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Axios from '@apricityhealth/web-common-lib/utils/Axios';

const CSV = require('neat-csv');

const CUBE_DEPTH_SCALAR = 0.01;
const MAX_NODES = 15000;

function roundTo(n, place) {
    return +(Math.round(n + "e+" + place) + "e-" + place);
}

export default class DLPPView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            patientId: props.patientId || '',
            darkMode: false,
            cubeDepth: 25,
            showAll: true,
            twoD: false,
            orthographic: true,
            negativeSlopes: false,
            positiveSlopes: false,
            progress: null,
            loading: null,
            reportJobs: [],
            dlppDim: 10,
            selectedJobId: '',
            sceneData: {
                patientIds: [],
                patients: []
            }
        }
        this.materials = [];
    }

    componentDidMount() {
        this.loadContent();
        this.token = PubSub.subscribe('PLAN_TOPIC', this.loadContent.bind(this));
    }

    componentWillUnmount() {
        this.shutdownScene();
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        console.log("DLPPView.loadContent()")

        this.setState({ progress: <CircularProgress size={20} />, error: null })
        getReportJob(this.props.appContext, { jobId: '*', result: true, reportName: 'BiomarkerData', status: 'done' }).then((reportJobs) => {
            this.setState({ reportJobs, progress: null });
        }).catch((err) => {
            this.setState({ error: getErrorMessage(err) })
        })
    }

    loadReportJob() {
        const { selectedJobId, reportJobs } = this.state;
        const job = reportJobs.find((e) => e.jobId === selectedJobId);
        if (job) {
            console.log("loadReportJob:", job)

            this.setState({ loading: <CircularProgress />, error: null }, this.shutdownScene.bind(this));
            Axios({
                url: job.result,
                method: 'get'
            }).then((response) => {
                console.log("loadReportJob data:", response.data.length)
                return this.generateSceneData(response.data);
            }).then((sceneData) => {
                this.setState({ loading: null, sceneData, error: sceneData.truncatedData ? 'Data was truncated due to the size.' : null }, this.initializeScene.bind(this));
            }).catch((err) => {
                this.setState({ loading: null, error: getErrorMessage(err) });
            })
        }
    }

    normalizeSeries(series) {
        if (series.length > 0) {
            const keys = Object.keys(series[0]);
            for (let i = 0; i < keys.length; ++i) {
                const key = keys[i];
                if (key === 'eventTime' || key === 'patientId' || key === 'target') {
                    // skip values that can't be normalized
                    continue;
                }

                const values = series.map((e) => Number(e[key])).filter((e) => !Number.isNaN(e));
                const min = Math.min(...values, 0);
                const max = Math.max(...values);

                for (let k = 0; k < series.length; ++k) {
                    const value = Number(series[k][key]);
                    // only normalize if it looks un-normalized already, the min < max, and the value isn't NaN
                    if ((min < 0 || max > 1) && min < max && !Number.isNaN(value)) {
                        const normalizedValue = (value - min) / (max - min);
                        series[k][key] = normalizedValue;          // normalize 0.0 to 1.0
                    } else {
                        if (Number.isNaN(value)) {
                            series[k][key] = 0;
                        } else {
                            series[k][key] = value;
                        }
                    }
                }
            }
        }
        //console.log("normalizeSeries:", series );
        return series;
    }

    generateSceneData(data) {
        console.log("generateSceneData starting:", data);

        const sceneData = {
            totalNodes: 0,
            dlppDim: 0
        };

        const csvToSeries = data && data.length > 0 ? CSV(data) : Promise.resolve([]);
        return csvToSeries.then((series) => {
            console.log("series:", series);
            return this.normalizeSeries(series);
        }).then((series) => {
            sceneData.patientIds = [...new Set(series.map((e) => e.patientId))];
            sceneData.patients = [];

            for (let i = 0; i < sceneData.patientIds.length; ++i) {
                const patientId = sceneData.patientIds[i];
                const data = series.filter((e) => e.patientId === patientId);
                const dataKeys = Object.keys(data[0]).filter((e) => e !== 'patientId' && e !== 'eventTime' && e !== 'target');
                const dataPoints = dataKeys.length;
                const dlppDim = Math.ceil(Math.sqrt(dataPoints));           // round up
                if (sceneData.dlppDim < dlppDim) {
                    sceneData.dlppDim = dlppDim;
                }
                const dlppDimHalf = dlppDim / 2;
                const lastEventTime = data[0].eventTime;
                const firstEventTime = data[data.length - 1].eventTime;
                const days = Moment(lastEventTime).diff(Moment(firstEventTime), 'days', true);

                const patient = {
                    patientId,
                    firstEventTime,
                    lastEventTime,
                    days,
                    data,
                    nodes: []
                };

                console.log(`generateSceneData ${Math.floor((i / sceneData.patientIds.length) * 100)}%, totalNodes: ${sceneData.totalNodes}`)
                for (let j = 0; j < data.length; ++j) {
                    const dp = data[j];
                    let z = Moment(lastEventTime).diff(Moment(dp.eventTime), 'days', true);
                    for (let x = 0; x < dlppDim; ++x) {
                        for (let y = -0; y < dlppDim; ++y) {
                            const valueIndex = (y * dlppDim) + x;
                            if (valueIndex >= dataKeys.length) {
                                continue;           // because we are rounding up, we are going to get some invalid indexes at the end
                            }

                            const key = dataKeys[valueIndex];
                            const value = dp[key];
                            if (Number.isNaN(value)) {
                                continue;
                            }
                            const node = {
                                key,
                                value,
                                slope: 0,
                                x: x - dlppDimHalf,
                                y: y - dlppDimHalf,
                                z
                            }
                            if (j > 0) {
                                const prevDp = data[j - 1];
                                node.prevValue = prevDp[key];
                            }
                            if (data.length > (j + 1)) {
                                const nextDp = data[j + 1];
                                node.nextZ = Moment(lastEventTime).diff(Moment(nextDp.eventTime), 'days', true);
                                node.nextValue = nextDp[key];
                                if (!Number.isNaN(node.nextValue)) {
                                    if (node.z < node.nextZ) {
                                        node.slope = (node.value - node.nextValue) / (node.nextZ - node.z); //getSlope([node.nextZ, node.z], [node.nextValue, node.value]);
                                    } else {
                                        node.slope = node.value - node.nextValue;           // z is the same, so just show the diff
                                    }
                                }
                            }
                            // don't add nodes where the value and slope are both zero..
                            //console.log("node:", node)
                            if (node.value !== 0 || node.slope !== 0 || (node.prevValue !== undefined && node.prevValue !== 0)) {
                                patient.nodes.push(node);
                                sceneData.totalNodes += 1;
                            }
                        }
                    }
                }

                sceneData.patients.push(patient);
                if (sceneData.totalNodes > MAX_NODES) {
                    sceneData.truncatedData = true;
                    break;
                }
            }

            console.log("generateSceneData done:", sceneData);
            return sceneData;
        })
    }

    initializeScene(resetCamera = false) {
        const { sceneData, darkMode, showAll, negativeSlopes, positiveSlopes, twoD, orthographic, cubeDepth } = this.state;
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        const addPointLight = ({ x, y, z, color = 0xffffff, intensity = 10000 }) => {
            const light = new THREE.PointLight(color, intensity);
            light.position.set(x, y, z);
            this.scene.add(light);
        }

        if (!this.scene) {
            this.scene = new THREE.Scene();
        } else {
            this.cleanUpCubesAndMaterials();
        }

        this.scene.add(new THREE.AmbientLight(0x404040));
        addPointLight({ x: 0, y: 50, z: 20 });

        if (!this.renderer) {
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setClearColor(darkMode ? 0 : 0xffffff);
            this.mount.appendChild(this.renderer.domElement);
        }
        this.renderer.setSize(width, height);

        if (!this.camera || resetCamera) {
            if (orthographic) {
                const aspect = width / height;
                const w = sceneData.dlppDim * aspect;
                const v = sceneData.dlppDim;
                this.camera = new THREE.OrthographicCamera(-w, w, v, -v, 0, 1000);
                this.camera.position.z = sceneData.dlppDim;
                if (!twoD) {
                    this.camera.position.y = sceneData.dlppDim * 0.5;
                    this.camera.position.x = sceneData.dlppDim * 0.5;
                }
            } else {
                this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
                this.camera.position.z = 15;
                if (!twoD) {
                    this.camera.position.y = 5;
                    this.camera.position.x = 5;
                }
            }
        }

        if (this.controls) {
            this.controls.dispose();
            this.controls = null;
        }
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        if (this.cube) {
            this.cube.dispose();
            this.cube = null;
        }
        this.cube = new THREE.BoxGeometry(1, 1, cubeDepth * CUBE_DEPTH_SCALAR);

        //ADD Your 3D Models here
        const addCube = ({ x = 0, y = 0, z = 0, color = 0xff0000, opacity = 0.5, userData = {} } = {}) => {
            const material = new THREE.MeshStandardMaterial({ color });
            material.transparent = true;
            material.opacity = opacity;
            //material.blending = THREE.AdditiveBlending;
            //material.blending = THREE.MultiplyBlending;
            const mesh = new THREE.Mesh(this.cube, material);
            mesh.position.set(x, y, z * (cubeDepth * CUBE_DEPTH_SCALAR));
            mesh.userData = userData;

            this.scene.add(mesh);
            this.materials.push(material);
        }

        const mid = new THREE.Color(0x808080);
        const self = new THREE.Color('blue');
        const worse = new THREE.Color(0xff0000);
        const better = new THREE.Color(0x00ff00);

        for (let i = 0; i < sceneData.patients.length; ++i) {
            const patient = sceneData.patients[i];
            const { patientId } = patient;
            if (!showAll && patientId !== this.state.patientId) {
                continue;           // skip any that are not our patient
            }
            for (let k = 0; k < patient.nodes.length; ++k) {
                const node = patient.nodes[k];
                const { x, y, z, value, slope } = node;

                if ((positiveSlopes && negativeSlopes)) {
                    if (slope === 0) continue;
                }
                else if (positiveSlopes && slope <= 0) continue;
                else if (negativeSlopes && slope >= 0) continue;

                const color = patientId === this.state.patientId ? new THREE.Color(self) : new THREE.Color(mid);
                if (slope < 0) {
                    color.lerp(better, Math.max(Math.min(-slope, 1), 0.5));
                } if (slope > 0) {
                    color.lerp(worse, Math.max(Math.min(slope, 1), 0.5));
                }
                addCube({ x, y, z: -z, color, opacity: Math.max(Math.min(value, 0.5), 0.25), userData: { node, color, patientId } });
            }
        }

        if (!this.raycaster) {
            this.raycaster = new THREE.Raycaster();
        }
        if (!this.pointer) {
            this.pointer = new THREE.Vector2();
        }

        if (!this.pointerMoveHandler) {
            this.pointerMoveHandler = this.onPointerMove.bind(this);
            window.addEventListener('mousemove', this.pointerMoveHandler);
        }

        this.renderScene();
        this.startAnimations();
    }

    onPointerMove(e) {
        if (this.pointer && this.mount) {
            const r = this.mount.getBoundingClientRect();
            this.mousePosition = { x: e.clientX - r.left, y: e.clientY - r.top, r };
            this.pointer.x = (this.mousePosition.x / r.width) * 2 - 1;
            this.pointer.y = -((this.mousePosition.y / r.height) * 2 - 1);
            //console.log("onPointerMove:", this.pointer, r, e.clientX, e.clientY );
        }
    }

    cleanUpCubesAndMaterials() {
        //console.log("cleanUpCubesAndMaterials:", this.cubes, this.materials );
        if (this.scene) {
            this.scene.clear();
        }
        for (let i = 0; i < this.materials.length; ++i) {
            this.materials[i].dispose();
        }
        this.materials = [];
    }

    shutdownScene() {
        this.stopAnimations();

        if (this.renderer) {
            this.mount.removeChild(this.renderer.domElement);
            this.renderer.dispose();
            this.renderer = null;
        }
        if (this.pointerMoveHandler) {
            window.removeEventListener('mousemove', this.pointerMoveHandler);
            this.pointerMoveHandler = null;
        }
        if (this.cube) {
            this.cube.dispose();
            this.cube = null;
        }
        if (this.controls) {
            this.controls.dispose();
            this.controls = null;
        }
        this.cleanUpCubesAndMaterials();
    }

    startAnimations() {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate.bind(this));
        }
    }

    stopAnimations() {
        if (this.frameId) {
            cancelAnimationFrame(this.frameId);
            this.frameId = null;
        }
    }

    animate() {
        // animate models here
        this.renderScene();
        this.frameId = window.requestAnimationFrame(this.animate.bind(this));
    }

    renderScene() {
        if (this.renderer) {
            this.renderer.render(this.scene, this.camera);
        }
        if (this.raycaster) {
            if (this.intersects && this.intersects.length > 0) {
                // restore the original material color before we re-test intersecitons
                this.intersects[0].object.material.emissive.set(0); //color.set(this.intersects[0].object.userData.color);
            }
            this.raycaster.setFromCamera(this.pointer, this.camera);
            this.intersects = this.raycaster.intersectObjects(this.scene.children);
            if (this.intersects.length > 0) {
                //console.log("intersects:", this.intersects );
                const { userData } = this.intersects[0].object;
                this.intersects[0].object.material.emissive.set(0xffffff);
                if (this.state.intersects !== userData) {
                    this.setState({ intersects: userData });
                }
            } else if ( this.state.intersects ) {
                this.setState({ intersects: null });
            }

        }
    }

    render() {
        const { patientId, progress, error, darkMode, showAll, negativeSlopes, positiveSlopes, twoD, orthographic,
            cubeDepth, selectedJobId, reportJobs, loading, intersects } = this.state;

        let tooltip = '';
        if (intersects) {
            const { node, patientId } = intersects;
            tooltip = <React.Fragment>
                {node.key}<br />
                Day: {roundTo(node.z, 2)}, Value: {roundTo(node.value, 2)}, Slope: {roundTo(node.slope, 2)}<br />
                {node.nextZ !== undefined ? `Next Day: ${roundTo(node.nextZ, 2)}` : ''}{node.nextValue !== undefined ? `, Next Value: ${roundTo(node.nextValue, 2)}` : ''}<br />
                Patient: <Patient appContext={this.props.appContext} patientId={patientId} />
            </React.Fragment>;
        }
        return <Tooltip title={tooltip}
            onMouseMove={(e) => {
                const { clientX, clientY } = e;
                this.tooltipPos = { clientX, clientY }
            }}
            PopperProps={{
                modifiers: {
                    computeStyle: {
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    left: `${this.tooltipPos.clientX}px`,
                                    top: `${this.tooltipPos.clientY}px`
                                }
                            }
                        }
                    }
                }
            }}
        >
            <table style={{ width: '100%' }}><tbody>
                <tr>
                    <td>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControl style={{ margin: 5, width: 500 }}>
                                <InputLabel>Select BiomarkerData Report</InputLabel>
                                <Select value={selectedJobId} onChange={(e) => {
                                    this.setState({ selectedJobId: e.target.value }, this.loadReportJob.bind(this))
                                }}>
                                    {reportJobs.map((j) => {
                                        let label = `${j.createdAt}: `;
                                        if (!j.name) {
                                            label += `${j.args.dataId}`;

                                            if (j.args.value) {
                                                label += ` == ${j.args.value}`
                                            }
                                            if (j.args.gt) {
                                                label += ` > ${j.args.gt}`
                                            }
                                            if (j.args.lt) {
                                                label += ` < ${j.args.lt}`
                                            }
                                        } else {
                                            label += j.name;
                                        }

                                        return <MenuItem value={j.jobId}>{label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <SelectPatient appContext={this.props.appContext} label='Highlight Patient' patientId={patientId} onChange={(patientId) => this.setState({ patientId }, this.initializeScene.bind(this))} enableNone={true} />
                        </div>
                    </td>
                    <td align='right'>
                        <span style={{ color: 'red' }}>{error}</span>
                        <FormControlLabel label='Dark' control={<Checkbox checked={darkMode} onChange={(e) => {
                            if (this.renderer) {
                                this.renderer.setClearColor(e.target.checked ? 0 : 0xffffff);
                            }
                            this.setState({ darkMode: e.target.checked })
                        }} />} />
                        <FormControlLabel label='All Patients' control={<Checkbox checked={showAll} onChange={(e) => this.setState({ showAll: e.target.checked }, this.initializeScene.bind(this))} />} />
                        <FormControlLabel label='Slope -' control={<Checkbox checked={negativeSlopes} onChange={(e) => this.setState({ negativeSlopes: e.target.checked }, this.initializeScene.bind(this))} />} />
                        <FormControlLabel label='Slope +' control={<Checkbox checked={positiveSlopes} onChange={(e) => this.setState({ positiveSlopes: e.target.checked }, this.initializeScene.bind(this))} />} />
                        <FormControlLabel label='2D' control={<Checkbox checked={twoD} onChange={(e) => this.setState({ twoD: e.target.checked }, this.initializeScene.bind(this, true))} />} />
                        <FormControlLabel label='Orthographic' control={<Checkbox checked={orthographic} onChange={(e) => this.setState({ orthographic: e.target.checked }, this.initializeScene.bind(this, true))} />} />
                        <TextField style={{ width: 150 }} label='Depth' value={cubeDepth} type='number' onChange={(e) => {
                            this.setState({ cubeDepth: e.target.value }, () => {
                                if (this.depthDebounce) {
                                    clearTimeout(this.depthDebounce);
                                }
                                this.depthDebounce = setTimeout(() => {
                                    this.depthDebounce = null;
                                    this.initializeScene();
                                }, 500);
                            })
                        }} />
                        <IconButton onClick={this.loadContent.bind(this)} disabled={progress !== null}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} align='center'>
                        <div style={{ margin: 5, width: '98%', height: 700 }} ref={(mount) => this.mount = mount} onClick={(e) => {
                            if (intersects) {
                                this.setState({ patientId: intersects.patientId }, this.initializeScene.bind(this))
                            }
                        }}>
                            {loading}
                        </div>
                        <span><i>Hold left mouse button to rotate, Hold right mouse button to move, Use your mouse wheel to zoom.</i></span>
                    </td>

                </tr>
            </tbody></table>
        </Tooltip>
    }
}

